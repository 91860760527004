@import "/scss";


.socialNetworks{
  z-index: 30;

  width: 76px;
  height: 217px;

  background: rgba(217, 217, 217, 0.01);
  border: 2px solid #5548F7;
  box-shadow: inset -4px 22px 51px rgba(85, 72, 246, 0.45);
  border-radius: 5px;

  position: fixed;
  left: -4px;
  top: 50%;
  translate: 0% -50%;

  display: grid;
  grid-auto-rows: auto auto auto auto;
  align-items: center;
  justify-items: center;
  @include mediaMax(558px){
    display: none;
    position: absolute;
  }
}
