@import "/scss";

.block {
  margin-top: 170px;

  width: 100%;
  height: 285px;
  // background-color: #504A9B;
  background-image: url(../../assets/image/FonLine.png);
  background-position: center;
  display: flex;
  justify-content: center;
  @include mediaMax($screenMd) {
    height: 267px;
  }
  overflow: hidden;
}

.content {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  position: relative;
}

.title {
  margin-top: 50px;
  display: flex;
  color: #ffffff;
  font-size: $titleFontSize;
  position: relative;
  @include mediaMax($screenLg) {
    font-size: 45px;
    margin-top: 61px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }
}

.subtitle {
  color: #ffffff;
  font-size: $subtitleFontSize;
  margin-top: 40px;
  @include mediaMax($screenLg) {
    font-size: 25px;
  }
  @include mediaMax($screenMd) {
    font-size: 20px;
    margin-top: 33px;
    max-width: 243px;
  }
}

.img {
  position: absolute;
  top: -23px;
  right: -100px;
  // @include mediaMax(1200px) {
  //   right: -200px;
  // }
  @include mediaMax(1100px) {
    right: -200px;
  }
  @include mediaMax($screenLg) {
    top: -35px;
    right: -100px;
  }
  @include mediaMax($screenMd) {
    // right: -270px;
    top: -55px;
    svg {
      height: 274px;
    }
  }
  @include mediaMax($screenSm) {
    top: -20px;
    svg {
      width: 250px;
    }
  }
}

.coin {
  @include mediaMax($screenMd) {
    svg {
      width: 50px;
    }
  }
}

.coin1 {
  position: absolute;
  top: -426px;

  animation: infinite 3.5s imageAnimation;
}

.coin2 {
  position: absolute;
  top: -810px;
  left: 330px;
  zoom: 80%;
  animation: infinite 4s imageAnimation;
  @include mediaMax($screenSm) {
    left: 130px;
  }
}

.coin3 {
  position: absolute;
  top: -420px;
  left: -230px;
  zoom: 110%;

  animation: infinite 3s imageAnimation;
}

.coin3 {
  position: absolute;
  top: -540px;
  left: -80px;
  zoom: 60%;
  @include mediaMax($screenSm) {
    left: 250px;
    // top: -300px;
  }
  animation: infinite 4s imageAnimation;
}

.coin4 {
  position: absolute;
  top: -610px;
  left: 80px;
  zoom: 90%;
  animation: infinite 6s imageAnimation;
}

.coin5 {
  position: absolute;
  top: -300px;
  left: 580px;
  zoom: 90%;
  @include mediaMax($screenSm) {
    left: 140px;
  }
  animation: infinite 5s imageAnimation;
}

.coin6 {
  position: absolute;
  top: -650px;
  left: 550px;
  zoom: 85%;
  @include mediaMax($screenSm) {
    left: 160px;
  }
  animation: infinite 4s imageAnimation;
}

.coin7 {
  position: absolute;
  top: -390px;
  left: -200px;
  zoom: 110%;
  @include mediaMax($screenSm) {
    left: 150px;
  }
  animation: infinite 3s imageAnimation;
}

@keyframes imageAnimation {
  0% {
    transform: rotate(-3deg);
    // top: -160px;
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
    top: 460px;
  }
}
