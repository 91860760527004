@import "/scss";


.modal {
  height: 583px;
  width: 845px;
  display: grid;
  grid-template-columns: 403px 442px;
  background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgForOpenBox.png") no-repeat;
  font-family: $mainFontFamily;
  font-style: normal;
  @include mediaMax(865px){
    height: 633px;
    width: 536px;
    margin: 10px;
    background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgMediumModalWindow.png") no-repeat;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    align-items: center;
    justify-items: center;
  }
  @include mediaMax(540px){
    background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgSmallModalWindow.png") no-repeat;
    height: 688px;
    width: 311px;
  }
}

.rightSide {
  display: grid;
  align-items: center;
  justify-items: center;
  align-self: center;
  justify-self: center;

  img {
    margin-top: -40px;
    width: 282px;
  }
  @include mediaMax(865px){
    img{
      width: 180px;
      margin-top: -10px;
    }
  }
  @include mediaMax(540px){
    img{
      width: 140px;
      margin-top: 10px;
    }
  }
}

.leftSide {
  display: grid;
  @include mediaMax(865px){
    align-items: center;
    justify-items: center;
  }
}

.title {
  margin-top: 50px;

  font-weight: 400;
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: center;

  color: #FFFFFF;
  @include mediaMax(865px){
    margin-top:0 ;
    font-size: 54px;
    text-align: center;
  }
  @include mediaMax(540px){
    font-size: 32px;
  }

}

.typeCollection {
  margin-top: -10px;

  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #E63AF1;
  @include mediaMax(865px){
    font-size: 18px;
    margin-top: -4px;
  }
  @include mediaMax(540px){
   width: 290px;
    text-align: center;
    padding-bottom: 6px;
  }
}

.titleNumber {
  margin-top: -20px;

  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #E63AF1;
  @include mediaMax(865px){
    margin-top: 0;
    font-size: 18px;
  }
  @include mediaMax(540px){
    padding-bottom: 10px;
  }
}

.description {
  width: 388px;
  height: 134px;

  font-weight: 400;
  font-size: 20px;
  line-height: 120.48%;

  color: #FFFFFF;
  @include mediaMax(865px){
    font-size: 16px;
  }
  @include mediaMax(540px){
    height: 180px;
    width: 240px;
    text-align: center;
  }
}

.blockchain {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;

  width: 320px;

  font-weight: 400;
  font-size: 20px;
  line-height: 120.48%;

  color: #FFFFFF;

  margin-bottom: 20px;

  img {
    justify-self: start;
  }
  @include mediaMax(540px){
    padding-left: 40px;
    font-size: 16px;
    grid-template-columns: 40px 190px;
    column-gap: 10px;
    img{
      width: 40px;
    }
  }

}

.button {
  margin-bottom: 20px;
}










