@import "/scss";

.logo {
  margin-right: 31px;
  @include mediaMax($screenLg) {
    svg {
      width: 86px;
      height: 74px;
    }
  }
  @include mediaMax($screenMd) {
    svg {
      width: 58px;
      height: 52px;
    }
    width: 100px;
    display: flex;
    justify-content: flex-start;
  }
}
