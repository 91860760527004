
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;

    overflow-x: hidden;
    width: 100vw;
    height: 100%;
    cursor: url('/src/assets/image/Purpleheart_wand1.png'), auto;

}

.link:hover {
    cursor: url('/src/assets/image/Purpleheart_wand2.png'), auto;;
}

button:hover {
    cursor: url('/src/assets/image/Purpleheart_wand2.png'), auto;;
}

a:hover {
    cursor: url('/src/assets/image/Purpleheart_wand2.png'), auto;;
}

#root {
    max-width: 100vw;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: "Superstar";
    src: url("./assets/fonts/superstar/superstar_memesbruh03.ttf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}
