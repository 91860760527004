@import "/scss";

.content {
  margin-top: 150px;
  // min-height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  @include mediaMax($screenMd) {
    margin-top: -150px;
  }
}

.title {
  width: 409px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: $titleFontSize;
  position: relative;
  line-height: 70px;
  // margin-right: 30px;
  svg {
    position: absolute;
    // right: -12px;
    top: -110px;
    right: -10px;
  }

  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 301px;
    line-height: 43px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
    width: 221px;
  }
}

.subtitle {
  font-family: $subMainFontFamily;
  max-width: 443px;
  font-size: $contetnFontSize;
  margin-top: 46px;
  line-height: 27px;
  @include mediaMax(1140px) {
    max-width: 403px;
  }
  @include mediaMax($screenLg) {
    width: 360px;
    font-size: 16px;
  }
  @include mediaMax($screenMd) {
    width: 300px;
    font-size: 16px;
  }
}

.paragraph {
  margin-bottom: 20px;
  @include mediaMax(1140px) {
    // width: 403px;
    margin: 0;
  }
  @include mediaMax($screenLg) {
    // margin-bottom: 20px;
  }

  //
}

.right {
  min-height: 450px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include mediaMax($screenMd) {
    margin-bottom: 370px;
  }
  @include mediaMax($screenSm) {
    margin-bottom: 270px;
  }
}

.left {
  position: absolute;
  left: 0;
  top: -50px;
  pointer-events: none;
  @include mediaMax(1340px) {
    left: -10%;
  }
  @include mediaMax(1240px) {
    left: -20%;
  }
  @include mediaMax(1140px) {
    left: -35%;
  }
  @include mediaMax($screenLg) {
    // zoom: 0.68;
    transform: scale(0.68);
    left: -15%;
  }
  @include mediaMax(910px) {
    left: -25%;
  }
  @include mediaMax($screenMd) {
    top: 450px;
    left: -100px;
  }
  @include mediaMax($screenSm) {
    // zoom: 0.5;
    top: 450px;
    left: -180px;
    transform: scale(0.5);
  }
}

.btns {
  margin-top: 38px;
}
.greenButton{
  background: url("../../assets/image/greenButton.png");

}

.pixel1 {
  right: -5px;
  top: 60px;
  position: absolute;
  animation: infinite 12s imageAnimation1;
}

.pixel2 {
  top: 65px;
  right: -180px;
  position: absolute;
  animation: infinite 12s imageAnimation2;
}

@keyframes imageAnimation1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes imageAnimation2 {
  0% {
    opacity: 0;
    right: -15px;
  }
  10% {
    opacity: 1;
    right: -180px;
  }
  90% {
    opacity: 1;
    right: -180px;
  }
  100% {
    opacity: 0;
    right: -5px;
  }

}