@import "/scss";

header {
  width: 100%;
  height: 140px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: relative;
  @include mediaMax($screenLg) {
    height: 120px;
  }
  @include mediaMax($screenMd) {
    height: 100px;
  }
}

.left {
  display: flex;
  align-items: center;
  position: relative;

  .logo {
    margin-right: 31px;
    @include mediaMax($screenLg) {
      svg {
        width: 106px;
        height: 94px;
      }
    }
    @include mediaMax($screenMd) {
      svg {
        width: 58px;
        height: 52px;
      }
    }
  }

  .tabs {
    > * + * {
      margin-left: 35px;
    }

    @include mediaMax(1070px) {
      display: none;
    }
  }
}

.menu {
  display: none;
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
  @include mediaMax(1070px) {
    display: flex;
    justify-content: flex-end;
    width: 100px;
  }
}

.hearts {
  display: flex;
  align-items: center;
  @include mediaMax($screenLg) {
    svg {
      width: 37px;
      height: 28px;
    }
  }
  @include mediaMax($screenMd) {
    svg {
      width: 24px;
      height: 20px;
    }
    width: 100px;
    display: flex;
    justify-content: center;
  }
}

a {
  color: $mainColorText;
  line-height: 22px;
  text-decoration: none;

  &:hover {
    color: #b5e627;
  }
}

.menu_block {
  position: absolute;
  width: 300px;
  height: 690px;

  background: #261738;
  border: 4px solid #ffffff;
  z-index: 20;
  top: -4px;
  right: -50px;
  padding: 40px 0 0 83px;
  font-family: $subMainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.close {
  position: absolute;
  right: 10%;
  top: 5%;
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
  z-index: 60;
}

.tabs__mobile {
  display: flex;
  flex-direction: column;
  position: relative;

  > * + * {
    margin-top: 30px;
  }
}

.btn {
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
}

.hearts1 {
  svg {
    animation: infinite 3s imageAnimation;
  }
}

.hearts2 {
  animation: infinite 3s imageAnimation1;
}

.hearts3 {
  svg {
    animation: infinite 3s imageAnimation2;
  }
}

@keyframes imageAnimation {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(0.8);
  }
  45% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1);
  }
}

@keyframes imageAnimation1 {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  45% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1);
  }
}

@keyframes imageAnimation2 {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  45% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(1);
  }
}

.newPoster {
  position: absolute;
  bottom: 53px;
  margin-left: -13px;
}
.addressConnectMob{
  position: relative;
  top: -20px;
  right: -40px;
}
.buttonDisconnectMob{
  margin-top: -16px;
}
.googlePlay {
  transition: 0.5s;

  &:hover {
    img {
      margin-left: 0;
      opacity: 1;
    }
  }

  img {
    margin-left: -10px;
    transition: 0.5s;
    opacity: 0;
    position: absolute;
  }
}
.rightHearts{
  position: absolute;
  display: none;
  @include mediaMax(1070px){
    position: relative;
    display: flex;
    align-items: center;
    @include mediaMax($screenLg) {
      svg {
        width: 37px;
        height: 28px;
      }
    }
    @include mediaMax($screenMd) {
      svg {
        width: 24px;
        height: 20px;
      }
      width: 100px;
      display: flex;
      justify-content: center;
    }
  }
}
.walletConnect {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;

  font-family: $subMainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  color: #FFFFFF;
  @include mediaMax(1070px){
    position: absolute;
    display: none;
  }

  .addressConnect {
    opacity: 0.7;
    padding-left: 20px;

  }

  .buttonDisconnect {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
    opacity: 1;

    margin-left: 10px;

  }
}

.icon {
  opacity: 0.5;
  transition: 0.5s;
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
}

.icon:hover path {
  opacity: 1 !important;
  transition: 0.5s;
  fill: #b5e627;
}

.icon:hover {
  opacity: 1 !important;
  filter: drop-shadow(0 0 5px #94a953);
  cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;;
}