@import "/scss";

.area{
  background: url("../../assets/imageBox/bgForBoxInformationArea.png") no-repeat;
  background-size: contain;
  width: 410px;
  height: 698px;
  display: grid;

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 26px;
  color: white;
  padding: 36px 24px 24px;
  z-index: 22;
  @include mediaMax(773px){
    width: 320px;
    height: 620px;
    background:url("../../assets/imageBox/bgForBoxInformationAreaMobileVersion.png") no-repeat ;
  }
}
.header{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  @include mediaMax(773px){
    width: 278px;
  }

}

.greenText{
  color: #B5E627;

}

.informAboutRound{
  justify-self: start;
  text-align: left;
  @include mediaMax(773px){
    font-size: 18px;
    line-height: 20px;
  }
}

.discount{
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  text-shadow: 1px 1px 0px #6C6C6C;

  justify-self: end;
  width: 160px;
  height: 49px;

  background: linear-gradient(95.24deg, #B5E627 -18.91%, #4FD4F1 110.6%);
  border-radius: 34px;
  display: grid;

  align-items: center;
  justify-items: center;

  @include mediaMax(773px){
    font-size: 16px;
    width: 138px;
  }
}
.timer{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;

  font-size: 56px;
  line-height: 61px;

  color: #FFFFFF;

  text-shadow: 2px -1px 0px #B5E627;
  @include mediaMax(773px){
    font-size: 36px;
    grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr;
    width: 250px;
    padding-left: 20px;

  }
}
.cellTimer{
  position: relative;
  top:-20px;

  display: grid;
  justify-items: center;

  height: 50px;
}
.nameCell{
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  text-shadow: none;

  color: #FFFFFF;

  opacity: 0.7;
  @include mediaMax(773px){
    display: none;
  }
}
.colon{
  position: relative;
  top: -4px;
  justify-self: center;
  @include mediaMax(773px){
    top: -20px;
  }
}
.line{
  justify-self: center;
  margin-bottom: 10px;
  margin-top: -6px;
  @include mediaMax(773px){
    margin-top: -15px;
    margin-bottom: 24px;
    width: 290px;
    position: relative;
    left: -14px;
  }
}
.areaDescriptionBox{
  width: 364px;
  height: 146px;

  background: #34194F;
  border-radius: 9px;

  display: grid;
  padding-left: 17px;
  padding-top: 23px;
  padding-bottom: 30px;

  grid-template-columns: 188px 88px;
  column-gap: 38px;
  grid-auto-rows: auto auto auto;

  margin-bottom: 20px;
  @include mediaMax(773px){
    width: 289px;
    text-align: left;
    grid-template-columns: auto 86px;
    margin-left: -10px;
  }
}
.amountBox{
  grid-column: 1;

  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;

  background: linear-gradient(180deg, #B5E627 0%, #4FD4F1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @include mediaMax(773px){
    font-size: 16px;
    line-height: 17px;
  }
}
.typesOfBox{
  grid-column: 1;

  font-size: 20px;
  line-height: 22px;

  @include mediaMax(773px){
    font-size: 16px;
    line-height: 17px;
  }
}

.peculiarity{
  grid-column: 1/3;

  font-size: 14px;
  line-height: 15px;

  color: #FFFFFF;

  opacity: 0.7;

  @include mediaMax(773px){
    font-size: 14px;
    line-height: 15px;
  }
}
.right{
  position: relative;
  top:14px;

  grid-column: 2;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  display: grid;
  align-items: center;
  justify-items: center;

  @include mediaMax(773px){
    right: 10px;
  }
}
.perBox{
  font-size: 14px;
  line-height: 15px;

  color: #FFFFFF;

  opacity: 0.7;
}
.price{
  font-size: 56px;
  line-height: 61px;
  color: #FFFFFF;

  text-shadow: 2px -1px 0px #B5E627;
}
.button{
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }

  width: 364px;
  height: 59px;

  background: linear-gradient(91.41deg, #B5E627 -19.27%, #B2E52D -19.27%, #4FD4F1 118.75%);

  display: grid;
  grid-template-columns: 1fr 1fr;

  padding-left: 17px;
  padding-right: 17px;

  font-size: 20px;
  line-height: 22px;

  color: #FFFFFF;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  align-items: center;

  margin-bottom: 25px;

  @include mediaMax(773px){
    width: 289px;
    margin-left: -10px;
  }
}

.priceInButton{
  justify-self: end;
}
.mintOn{
  justify-self: start;
}

.priceAmount{
  font-size: 56px;
  line-height: 61px;
  color: #FFFFFF;

  text-shadow: 2px -1px 0px #B5E627;

  @include mediaMax(773px){
    font-size: 32px;
    line-height: 35px;
  }
}
.descriptionPriceAmount{
  font-size: 14px;
  line-height: 15px;

  color: #FFFFFF;

  opacity: 0.7;
  justify-self: end;
}

.blockWithPrice{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  margin-bottom: 20px;
  @include mediaMax(773px){
    grid-template-columns: auto auto;
    width: 300px;
    margin-left: -20px;
  }
}

.descriptionProgressBar{
  display: grid;
  grid-template-columns: 96px 1fr;
  justify-content: space-between;
  margin-bottom: 10px;
  @include mediaMax(773px){
    font-size: 16px;
    line-height: 17px;
    margin-left: -24px;
    width: 300px;
  }
}
.progress{
  justify-self: end;
}
.progressBar{
  justify-self: center;

  margin-bottom: -30px;
  @include mediaMax(773px){
    margin-left: -24px;
  }
}


.bord{
  z-index: 20;
  position: relative;
  top: -35px;
  @include mediaMax(773px){

    right: 0;
  }
}
.scale{
  position: relative;
  left: 6px;
  height: 29px;
  background: #B5E627;

  z-index: 10;
}




