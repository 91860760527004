@import "/scss";

.content {
  margin-top: 70px;
  // margin-bottom: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -180px;
}

.blockGradLeft {
  width: 100%;
  height: 225px;
  background: #a5e347;
}

.blockGradRight {
  width: 100%;
  height: 225px;
  background-color: #96e064;
}

.gard {
  // position: absolute;
  // margin-top: -30px;
}

.PFooter {
  position: absolute;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-5%, -10%);
}

.text {
  font-family: "Superstar";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 119.48%;
  color: #ffffff;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  max-width: 522px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-115%, 0%);
  @include mediaMax(1231px){
    translate: 10%;
  }
  @include mediaMax(1133px){
    translate: 26%;
  }
  @include mediaMax(527px){
    font-size: 18px;
    line-height: 119.48%;
  }
}
