@import "/scss";

.content {
  min-height: 270px;
  margin-top: 70px;
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }
  @include mediaMax(1280px) {
    height: 1460px;
    align-items: center;
    justify-items: center;
    text-align: center;
    margin-top: 40px;
  }
}

.Maintitle {

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 98px;
  display: flex;
  align-items: center;

  color: #FFFFFF;

  text-shadow: 3px 2px 0 #E74FF1, 1px -2px 0px #B5E627;
  @include mediaMax(1280px) {
    font-size: 36px;
    line-height: 39px;
    text-align: center;
  }
}

.subTitleBlock {
  position: relative;

  margin-top: 40px;
  padding: 140px 40px 40px;

  font-family: $subMainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 151.68%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: left;

  color: rgb(255, 255, 255);
  opacity: 0.9;

  background: url("../../assets/imageBox/borderForSubTitleInNftPage.png") no-repeat;
  background-size: contain;

  width: 682px;
  height: 398px;

  @include mediaMax(773px) {
    background: url("../../assets/imageBox/borderForSubTitleInNftPageMobileVersion.png") no-repeat;

    width: 337px;
    height: 415px;

    margin-right: 2px;
    padding-top: 34px;
    padding-left: 16px;
    padding-right: 16px;

    font-size: 18px;
    line-height: 151.68%;
  }
}

.subTitleGrad {
  font-family: $mainFontFamily;
  font-size: 32px;
  background: linear-gradient(
                  90.22deg,
                  #b5e627 0.09%,
                  #4fd4f1 20.49%,
                  #e74ff1 30.56%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @include mediaMax(1280px) {
    background: linear-gradient(90.22deg, #b5e627 30.09%, #4fd4f1 44.49%, #e74ff1 65.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.subTitle {
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  position: absolute;
  z-index: 10;
}

.buyBox {
  width: 100%;
  margin-top: 80px;
  display: flex;
  
  justify-content: space-between;
  @include mediaMax(1280px) {
    justify-content: center;
    margin-left: 70px;
  }
  @include mediaMax(773px) {
    display: grid;
    margin-top: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    gap: 26px;
    margin-left: 0;
    justify-items: center;
  }
}

.button {
  background: url("../../assets/image/greenButton.png") no-repeat;

  margin-right: 12%;
  translate: 12%;
  @include mediaMax(773px) {
    margin-right: 0;
    translate: 0;
  }
}

.progressBar {
  margin-top: 120px;
}

.flare {
  position: absolute;
  top: -150px;
  pointer-events: none;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  pointer-events: none;
  @include mediaMax(1280px) {
    top: 800px;
    right: auto;
    left: 50%;
    translate: -50%;
  }

}

.pixie1 {
  z-index: 1;

  position: absolute;
  transform: rotate(-16.76deg);

  right: 50%;
  translate: 50%;

  margin-right: -130px;
  margin-top: 260px;
  @include mediaMax(1445px) {
    margin-right: -150px;
  }
  @include mediaMax(1314px) {
    display: none;
  }

  @include mediaMax(1280px) {
    display: block;
    height: 240px;
    margin-top: 960px;
    margin-right: 220px;
    rotate: -20deg;
  }
  @include mediaMax(773px) {
    display: none;
  }
}

.pixie2 {
  z-index: 1;

  position: absolute;
  transform: rotate(24deg);

  right: 50%;
  translate: 50%;

  margin-right: -630px;
  margin-top: 360px;
  @include mediaMax(1405px) {
    margin-right: -620px;
  }
  @include mediaMax(1280px) {
    height: 420px;
    margin-top: 580px;
    margin-right: -134px;
  }
  @include mediaMax(773px) {
    height: 300px;
    margin-top: 640px;
    margin-right: -120px;
  }
}

.sealRed {
  position: absolute;
  width: 311px;
  transform: rotate(-23.4deg);
  margin-left: 640px;
  margin-top: -60px;
  z-index: 50;
  @include mediaMax(1413px) {
    margin-left: 620px;
  }
  @include mediaMax(1314px) {
    margin-left: 600px;
  }
  @include mediaMax(1280px) {
    transform: rotate(-23.4deg);
    margin-left: -400px;
    margin-top: 750px;
  }
  @include mediaMax(773px) {
    width: 184px;
    transform: rotate(-8.89deg);
    margin-left: -200px;
    margin-top: 760px;
  }
}

.buttonVideo{
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  align-items: center;  

    font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;
  gap: 10px;
}


