@import "/scss";

footer {
  margin-top: 150px;
  height: 426px;
  width: 100%;
  background: #261738;
  display: flex !important;
  justify-content: center;
  padding-top: 69px;
  font-family: $subMainFontFamily;
  @include mediaMax($screenMd) {
    padding-top: 20px;
    height: 500px;
  }
}

a {
  color: $mainColorText;
  line-height: 22px;
  text-decoration: none;

  &:hover {
    color: #b5e627;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  @include mediaMax($screenMd) {
    flex-direction: column;
    align-items: center;
  }
}

.content__tabs {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.logo {
  width: 25%;
  @include mediaMax($screenMd) {
    display: flex;
    justify-content: center;
    min-width: 124px;
    margin-bottom: 10px;
  }
}

.tabs {
  width: 27%;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 25px;
  }

  @include mediaMax($screenMd) {
    width: 50%;
    > * + * {
      margin-top: 22px;
    }
  }
}

.tab__mobile {
  display: none;
  @include mediaMax($screenMd) {
    display: block;
  }
}

.tab {
  font-size: 18px;
  @include mediaMax($screenMd) {
    font-size: 16px;

    // display: flex;
    // justify-content: center;
  }
}

.tabs__Name {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.ContactUs {
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  @include mediaMax($screenMd) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    min-width: 250.04px;
  }
}

.email {
  margin-top: 34px;
}

.column3__mobile {
  display: none;
  @include mediaMax($screenMd) {
    display: flex;
    align-items: center;
    font-size: 18px;
    width: 100%;
  }
}

.column3 {
  @include mediaMax($screenMd) {
    display: none;
  }
}
