@import "/scss";

.content {
  min-height: 710px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 70px;
  @include mediaMax($screenLg) {
    justify-content: center;
    align-items: start;
    margin-top: 70px;
  }
}

.title {
  width: 684px;

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 76px;
  line-height: 83px;
  display: flex;
  align-items: center;

  color: #FFFFFF;

  text-shadow: 0px 4px 0px rgba(231, 79, 241, 0.5), 4px 4px 0px #2773E6;
  @include mediaMax($screenLg) {
    display: none;
  }
}

.left {
  z-index: 2;
  @include mediaMax($screenLg) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.title__mobile {
  display: none;
  @include mediaMax($screenLg) {
    display: flex;
    justify-content: center;
    zoom: 140%;
  }
  @include mediaMax($screenMd) {
    display: block;
    zoom: 100%;
  }
}

.subtitle {
  font-family: $subMainFontFamily;
  max-width: 562px;
  font-size: $contetnFontSize;
  margin-top: 46px;
  margin-bottom: 70px;

  color: white;

  div {
    margin: 10px 0;
  }

  @include mediaMax($screenLg) {
    margin-top: 40px;
    text-align: center;
    font-size: 16px;
    max-width: 462px;
  }
  @include mediaMax($screenMd) {
    max-width: 311px;
  }
  @include mediaMax(992px){
    margin-bottom: -10px;
  }
}

.decoding {
  margin-top: 10px;
  font-family: $subMainFontFamily;
  font-size: 14px;
  @include mediaMax($screenMd) {
    font-size: 12px;
  }
}

.right {
  position: absolute;
  left: 190px;
  top: -400px;
  pointer-events: none;
  @include mediaMax($screenLg) {
    top: 280px;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-46%, 0%);
    zoom: 60%;
  }
  @include mediaMax($screenMd) {
    top: 760px;
    zoom: 40%;
  }
  @include mediaMax(441px){
    top: 960px;
  }

  img {
    animation: infinite 5s imageAnimation;
  }
}

@keyframes imageAnimation {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.btns {
  margin-top: 45px;
  display: flex;

  > * + * {
    margin-left: 45px;
  }
  @include mediaMax(441px){
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 300px;
    // grid-row-gap: 18px;
    justify-items: center;
  }
}
.buttonPlayVideo{
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #FFFFFF;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);

  @include mediaMax(441px){
    margin-left: -10px;
  }
}
.textWatchVideo{
  margin-left: 10px;
}

.socialNetworks {
  margin-top: 10px;
  @include mediaMax($screenLg) {
    margin-top: 450px;
  }
  @include mediaMax($screenMd) {
    margin-top: 300px;
  }
}

.flare {
  position: absolute;
  left: -500px;
  top: -340px;
  // display: none;
  pointer-events: none;
  @include mediaMax($screenLg) {
    top: -540px;
  }
  z-index: 0;
}

.join {
  width: 340px;
  margin: 40px 0;
  font-family: $mainFontFamily;
  font-size: $contetnFontSize;
  color: #fff;
  background: rgba(217, 217, 217, 0.01);
  border: 2px solid #5548F7;
  box-shadow: 0px 0px 54px rgba(85, 72, 246, 0.14), inset -4px 22px 51px rgba(85, 72, 246, 0.24);
  border-radius: 2px;
  height: 76px;
  transition: 0.2s;

  &:hover {

    transform: scale(1.05);
  }

  @include mediaMax($screenLg) {
    width: 320px;

  }
  @include mediaMax($screenMd) {
    width: 300px;
    font-size: 18px;
  }
}
