@import "/scss";

.step {

  display: flex;
  margin-left: 57px;
  margin-right: 15px;
  margin-bottom: 42px;

  :first-child {
    margin-right: 19px;
    @include mediaMax($screenLg) {
      margin-right: 11px;

    }
  }

  .step__text {
    max-width: 411px;
    width: 411px;
    font-size: 20px;
    line-height: 27px;
    margin-top: -5px;
    // height: 41px;
    @include mediaMax($screenLg) {
      font-size: 16px;
      line-height: 22px;
    }
    @include mediaMax($screenMd) {
      max-width: 351px;
      width: 100%;
    }
  }


  @include mediaMax($screenLg) {
    margin-left: 40px;
    width: 100%;
    margin-bottom: 37px;
  }
  @include mediaMax($screenMd) {
    margin-left: 11px;
    margin-bottom: 32px;
  }
}

.step__btn {
  @include mediaMax($screenLg) {
    svg {
      width: 14px;
    }
  }
  @include mediaMax($screenMd) {
    svg {
      width: 10px;
    }
  }
}
