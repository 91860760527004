//main
$mainFontFamily: "Superstar";
$subMainFontFamily: "Open Sans";

$mainTitleFontSize: 76px;
$titleFontSize: 64px;
$subtitleFontSize: 32px;
$contetnFontSize: 20px;

$titleRoadmapFontSize: 40px;
$subtitleRoadmapFontSize: 24px;

$titleFooterFontSize: 24px;
$subtitleFooterFontSize: 18px;

$mainColorText: rgba(255, 255, 255, 0.7);
