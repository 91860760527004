@import "/scss";

.content {
  margin-top: 150px;
  // min-height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include mediaMax($screenMd) {
    justify-content: center;
  }
}

.title {
  z-index: 2;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: $titleFontSize;
  position: relative;
  line-height: 70px;
  @include mediaMax($screenLg) {
    font-size: 45px;
    line-height: 33px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
    align-items: center;
    text-align: center;
  }
}

.subtitle {
  font-family: $subMainFontFamily;
  width: 443px;
  font-size: $contetnFontSize;
  margin-top: 46px;
  line-height: 27px;
  z-index: 2;
}

.blocks {
  display: flex;
  z-index: 2;
  margin-top: 92px;

  > * + * {
    margin-left: 150px;
  }

  font-size: 20px;
  @include mediaMax(1230px) {
    > * + * {
      margin-left: 15px;
    }
  }
  @include mediaMax($screenLg) {
    font-size: 16px;
  }
  @include mediaMax($screenMd) {
    flex-direction: column;
    align-items: center;
    margin-top: 59px;
    > * + * {
      margin-left: 0;
    }
  }
  @include mediaMax(475px) {
    margin-bottom: 200px;
  }
}

.block {
  width: 507px;
  height: 85px;
  z-index: 2;
  border: 2px solid #e74ff1;
  margin-bottom: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  @include mediaMax(1230px) {
    width: 457px;
  }
  @include mediaMax($screenLg) {
    width: 311px;
    height: 60px;
  }
}

.img {
  z-index: 2;

  img {
    width: 100%;
  }

  @include mediaMax($screenLg) {
    display: none;
  }
}

.img__mobile {
  z-index: 2;
  width: 70%;

  img {
    width: 100%;
  }

  display: none;
  @include mediaMax($screenLg) {
    display: block;
  }
  @include mediaMax($screenSm) {
    width: 100%;
  }
  @include mediaMax(475px) {
    position: absolute;
    top: 600px;
    width: 444px;
    // left: -20px;
  }
}

.flare {
  position: absolute;
  top: -500px;
  right: -400px;
  pointer-events: none;
  z-index: 0;
  @include mediaMax($screenMd) {
    opacity: 0.8;
  }
}
