@import "/scss";

.App {
  // text-align: center;
  background-color: #0b0027;
  font-family: $mainFontFamily;
  color: $mainColorText;
  font-style: normal;
  font-weight: 400;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  // min-height: 1000vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#ecosystem {
  @include mediaMax($screenLg) {
    padding: 0;
  }
}

.lineGreen {
  width: 100%;

}