@import "/scss";


.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.5s;
  z-index: 100;

  &__content {
    transform: scale(0.5);
    transition: 0.5s;
  }

  &__content.active {
    transform: scale(1);
  }
}

.window_default {
  transition: 0.5s;
  min-width: 305px;
  min-height: 207px;
  background: #0b0027;
  border: 2px solid #4FD4F1;
  backdrop-filter: blur(85px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 50px 50px 30px 50px;
  @include mediaMax($screenMd) {
    padding: 50px 35px 30px 35px;

  }
  @include mediaMax(540px){
    border: none;
    background: url("../../assets/image/imgForOpenMysteryBoxPage/bgSmallForPay.png") no-repeat;

  }
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal__close_button {
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
  position: absolute;
  top: 25px;
  z-index: 10000;
  right: 20px;
  border: none;
  background: transparent;
}
