@import "/scss";

.button {
  background: url("../../assets/image/imgForOpenMysteryBoxPage/borderForButton.svg") no-repeat;
  background-size: contain;
  width: 150px;
  height: 64px;
  display: grid;
  align-items: center;
  justify-items: center;

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);

  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
}