@import "/scss";

.content {
  min-height: 270px;
  margin-top: 70px;
  margin-bottom: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }
}

.title {
  font-size: $titleFontSize;
  width: 675px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  position: relative;
  line-height: 35px;
  // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 600px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__text {
    img {
      position: absolute;
      top: -90px;
      right: 120px;
    }
    @include mediaMax($screenLg){
      margin-top: 40px;
      img{
        top:-50px;
      }
    }
    @include mediaMax(629px){
      margin-top: 20px;
      img{
        width: 140px;
        right: 150px;
        top: -40px;
      }
    }
  }
}

.Maintitle {
  font-weight: 400;
  font-size: 155px;
  line-height: 169px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 5px 3px 0px #e74ff1, 0px -3px 0px #b5e627;

  @include mediaMax(1065px){
    font-size: 100px;
    line-height: 109px;
    margin-bottom: -60px;
  }
  @include mediaMax(629px){
    font-size: 40px;
    line-height: 44px;
  }
}

.center {
  margin: 100px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include mediaMax(1065px){
    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;
    justify-content: center;
  }

  .left {
    width: 600px;
    @include mediaMax(1065px){
      grid-row: 2;
    }


    .leftTitle {
      width: 580px;
      font-weight: 400;
      font-size: 50px;
      line-height: 54px;
      display: flex;
      align-items: center;

      color: #ffffff;
      @include mediaMax(629px){
        font-size: 26px;
        line-height: 28px;
        width: 330px;
        text-align: center;
        position: relative;
        left: 50%;
        translate: -50%;
      }
    }

    .leftText {
      font-family: $subMainFontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;

      color: #ffffff;

      opacity: 0.7;
      margin: 70px 0;

      @include mediaMax(629px){
        margin-top: 20px;
        font-size: 20px;
        line-height: 24px;
        width: 330px;
        text-align: center;
        position: relative;
        left: 50%;
        translate: -50%;
      }
    }

    .item {
      display: flex;
      gap: 22px;
      margin-bottom: 33px;
      font-family: $subMainFontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      align-items: center;

      color: rgba(255, 255, 255, 0.7);

      .dot {
        width: 18px;
        height: 18px;
        background: #b5e627;
        box-shadow: 0 0 25px #b5e627;
      }

    }
  }
  .dots{
    @include mediaMax(629px){
      position: relative;
      left: 50%;
      translate: -20%;

    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;

    // .rightRound {
    //   width: 243px;
    //   height: 74px;
    //   background: #504a9b;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   font-size: 30px;
    //   line-height: 33px;

    //   color: #ffffff;

    //   @include mediaMax(1065px){
    //     display: grid;
    //     position: relative;
    //     left: 50%;
    //     translate: -50%;
    //   }
    // }

    .rightImg {
      margin-top: -140px;
      margin-left: -50px;
    }
    @include mediaMax(1212px){
      position: relative;
      left: -100px;
    }
    @include mediaMax(1125px){
      position: relative;
      left: -150px;
    }
    @include mediaMax(1065px){
      display: grid;
      position: relative;
      left: 50%;
      translate: -50%;
    }
    @include mediaMax(629px){
      left: 35%;
    }
  }
}
.box{
  width: 634px;

  position: relative;
  top: 40px;
  right: 40px;
  margin-left: 130px;

  filter: drop-shadow(0px 0px 163px rgba(189, 0, 255, 0.5));

  @include mediaMax(629px){
    width: 340px;
    margin-top: 50px;

  }
}

.buttonBuyBox{
  width: 149px;
  height: 63.81px;

  display: grid;
  align-items: center;
  justify-items: center;

  background: url("../../assets/image/raimbowButton.png") no-repeat;
  background-size: contain;

  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

  @include mediaMax(629px){
    position: relative;
    left: 50%;
    translate: -50%;


  }
}

.wProgressBar {
  margin-top: -126px;
  display: grid;

  margin-left: 40px;
  @include mediaMax(1065px){
    margin-top: -100px;
    margin-bottom: -60px;
    margin-left: -20px;
  }
  @include mediaMax(540px){
    margin-left: 0;
  }
  @include mediaMax(350px){
    margin-right: 2px;
  }


}
  .progressBar {
    margin-top: 60px;


  }
