@import "/scss";

.content {
  min-height: 710px;
  margin-top: 170px;
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;
  position: relative;
  @include mediaMax($screenLg) {
    margin-top: 78px;
  }
  @include mediaMax($screenMd) {
    align-items: center;
    min-height: 310px;
  }
}

.title {
  // width: 683px;
  font-size: $mainTitleFontSize;
  color: #ffffff;
  display: flex;
  align-items: center;
  @include mediaMax($screenLg) {
    font-size: 45px;
    justify-content: center;
    flex-direction: column;
    margin-right: 120px;

  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }
}

.subtitle {
  // font-family: $subMainFontFamily;
  width: 562px;
  font-size: $subtitleFontSize;
  width: 355px;
  height: 57px;
  background: #e74ff1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
  @include mediaMax(1100px) {
    width: 240px;
    font-size: 25px;
  }
  @include mediaMax($screenLg) {
    width: 265px;
    height: 50px;
    margin-left: 185px;
    margin-top: 10px;
  }
  @include mediaMax($screenMd) {
    font-size: 20px;
    width: 231px;
    height: 46px;
  }
}

.slider {
  // margin-top: 134px;
  position: relative;
  display: flex;
  justify-content: center;
  @include mediaMax($screenMd) {
    width: 105%;
  }
  @include mediaMax($screenSm) {
    width: 120%;
  }
}

.sliderArrowL {
  position: absolute;
  top: 280px;
  left: -40px;
}

.sliderArrowR {
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
  position: absolute;
  top: 280px;
  right: -40px;
}

.tree {
  position: absolute;
  right: -40px;
  top: 30px;
  pointer-events: none;
  // display: none;
  @include mediaMax(1350px) {
    top: 100px;
  }
  @include mediaMax($screenLg) {
    // display: none;
    left: 0;
  }
  @include mediaMax($screenMd) {
    zoom: 70%;
    left: -120px;
  }
  @include mediaMax($screenSm) {
    zoom: 60%;
    top: 80px;
    left: -220px;
  }
}

.flare {
  position: absolute;
  left: -550px;
  top: -500px;
  pointer-events: none;
}

.tree {
  // display: none;
  img {
    // display: none;
    animation: infinite 5s imageAnimation;
  }
}

@keyframes imageAnimation {
  0% {
    transform: scale(1);
    // top: -160px;
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
