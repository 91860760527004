@import "normalize";
@import "media";
@import "main";

:root {
  --animation-time: 3s;
}

.container {
  width: 100%;
  max-width: 1440px;
  display: block;
  padding: 0 130px;
  @include mediaMax($screenEx) {
    padding: 0 90px;
  }
  @include mediaMax($screenLg) {
    padding: 0 50px;
  }
  @include mediaMax($screenMd) {
    padding: 0 32px;
  }
}
.lineImformAboutBoxes{
  position: fixed;
  z-index: 100;
  bottom: 0;
  @include mediaMax(1258px){
    display: none;
  }
}

