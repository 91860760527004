.blockLine1 {
  width: 100%;
  height: 2px;
  background-color: #B5E627;
  position: relative;
}

.protrusion {
  height: 21px;
  width: 224px;
  border: 2px solid #B5E627;
  position: absolute;
  left: 50%;
  transform: translate(-42%, 0%);
  bottom: 0px;
  background: #0b0027;
  border-bottom: 1px solid #0b0027;
  z-index: 10;
}
