@import "/scss";

.card {
  width: 362px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include mediaMax($screenMd) {
    transform: scale(0.8);
  }
  @include mediaMax($screenSm) {
    transform: scale(0.6);
  }
}

.title__text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 270.72px;
  height: 82.38px;
  font-size: 23px;
  @include mediaMax($screenMd) {
    font-size: 27.6px;
  }
  @include mediaMax($screenSm) {
    // zoom: 60%;
  }
}

.title {
  width: 282.72px;
  height: 82.38px;
  position: relative;
  display: flex;
  justify-content: center;
  // font-size: $subtitleFontSize;
  color: #ffffff;

}

.content {
  width: 282.72px;
  height: 226.79px;
  background: #3c3352;
  font-family: $subMainFontFamily;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content__text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250.72px;
  // height: 226.79px;

}
