@import "/scss";

.socialNetworks {
  display: flex;

  > * + * {
    margin-left: 32px;
  }

  @include mediaMax($screenMd) {
    > * + * {
      margin-left: 26px;
    }
  }
}

.icons {
  display: flex;
  display: -webkit-flex;

  > * + * {
    margin-left: 23px;
  }
}

.icon {
  &:hover{
    cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;
  }
  opacity: 0.5;
  transition: 0.5s;
}

.icon:hover path {
  opacity: 1 !important;
  transition: 0.5s;
  fill: #b5e627;
}

.icon:hover {
  opacity: 1 !important;
  filter: drop-shadow(0 0 5px #94a953);
  cursor: url('../../assets/image/Purpleheart_wand2.png'), auto;;
}
