@import "/scss";


.alice-carousel__dots-item {
  width: 8.68px !important;
  height: 8.68px !important;
  background: #ffffff57 !important;
  border-radius: 0% !important;

}

.alice-carousel__dots-item:not(.__custom).__active {
  background: #b5e627 !important;
  box-shadow: 0px 0px 25px #b5e627 !important;
  cursor: url("../../assets/image/Purpleheart_wand2.png"), auto !important;
}
