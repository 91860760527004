@import "/scss";

.bar{
  position: relative;
  justify-items: center;
  justify-self: center;
  justify-content: center;
  background: url("../../assets/imageBox/nftBuyScaleBig.png") no-repeat;
  background-size: contain;
  width: 1250px;
  height: 70px;
  z-index: 10;
  @include mediaMax(1312px){
    width: 1000px;
  }
  @include mediaMax(1120px){
    background: url("../../assets/imageBox/nftBuyScale.png") no-repeat center;
    width: 300px;
  }
}
.fillingProgressBar{
  z-index: 1;
  height: 44px;
  background: #B5E627;
  position: relative;
  top: -58px;
  @include mediaMax(1312px){
    height: 39px;
    top: -59px;
  }
  @include mediaMax(1120px){
    height: 26px;
    top:-48px;
  }
}
.soldNft{
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  text-align: center;

  color: #FFFFFF;
}
.loading{
  font-size: 30px;
  line-height: 33px;

  color: #FFFFFF;
  text-align: center;
  margin-bottom: -40px;
  margin-top: 30px;
}