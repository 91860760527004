@import "/scss";

.content {
  min-height: 270px;
  margin-top: 150px;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }

}

.title {
  font-size: $titleFontSize;
  width: 675px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  position: relative;
  line-height: 35px;
  // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 600px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__text {
    img {
      position: absolute;
      top: -90px;
      right: 120px;
    }
  }
}

.subTitleBlock {
  position: relative;
  margin-top: 100px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.subTitle {
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  z-index: 10;
  max-width: 1000px;
  @include mediaMax(959px){
    margin-top: -80px;
    font-size: 46px;
    line-height: 52px;
  }
  @include mediaMax(547px){
    font-size: 32px;
    line-height: 35px;
  }
}

.center {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  @include mediaMax(1200px){
    display: grid;
    justify-content: center;
    margin-top: 70px;
  }
  @include mediaMax(547px){
    margin-top: 58px;
  }
  .left{
    @include mediaMax(1200px){
      margin-bottom: 28px;
    }
  }
  .right,
  .left {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .item {
    border: 2px solid #b5e627;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 500px;
    height: 85px;
    font-size: 20px;
    line-height: 22px;
    padding: 0 40px;

    color: #ffffff;

    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    @include mediaMax(547px){
      width: 313px;
      height: 68px;
    }
  }
}

.museum {
  margin-top: 60px;
  margin-left: 40px;
  @include mediaMax(1200px){
    width: 100%;
  }
  @include mediaMax(547px){
    padding-right: 20px;
  }
}

.btn {
  z-index: 2;
  margin-top: 69px;
}
.button{
  z-index: 2;
  background: url("../../assets/image/raimbowButton.png") no-repeat;
}

.starsBtn {
  z-index: 1;
  margin-top: -120px;
}