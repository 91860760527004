@import "/scss";

.content {
  width: 436px;
  height: 84px;

  background: radial-gradient(
                  97.33% 2816.41% at 2.67% 13.79%,
                  #e74ff1 0%,
                  #b5e627 100%
  )
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
;
  border: 9px solid #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 0 30px;
  @include mediaMax($screenLg) {
    width: 376px;
    height: 69px;
    border: 7px solid #ffffff;
  }
  @include mediaMax($screenMd) {
    width: 286px;
    height: 54px;
    border: 4px solid #ffffff;
  }
  @include mediaMax(500px) {
    width: 100%;
    min-width: 286px;
  }
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: $titleRoadmapFontSize;
  color: #ffffff;
  @include mediaMax($screenLg) {
    font-size: 32px;
  }
  @include mediaMax($screenMd) {
    font-size: 24px;
  }
}

.status {
  font-size: $subtitleRoadmapFontSize;
  @include mediaMax($screenLg) {
    font-size: 20px;
  }
  @include mediaMax($screenMd) {
    font-size: 16px;
  }
}

.arrow {
  @include mediaMax($screenLg) {
    margin-top: 5px;
    svg {
      width: 38px;
    }
  }
  @include mediaMax($screenMd) {
    margin-top: 5px;
    svg {
      width: 28px;
    }
  }
}