@import "/scss";

:global(.rc-slider-handle){
  background: linear-gradient(89deg, #4FD4F1 0.02%, #D14FF1 100.02%) !important;
  opacity: 1;
  width: 20px !important;
  height: 20px !important;
  margin-left: 10px !important;
}

:global(.rc-slider-track){
  background: linear-gradient(270.02deg, #4FD4F1 0.02%, #D14FF1 100.02%) !important;
}

.content {
  height: auto;
  display: grid;
  margin-top: -50px;
}

.areaForBoxes {
  display: grid;
  grid-template-columns: 316px 316px 316px;
  row-gap: 40px;
  column-gap: 128px;
  justify-self: center;
  @include mediaMax(1220px){
    column-gap: 40px;
  }
  @include mediaMax(1020px){
    grid-template-columns: 316px 316px;
  }
  @include mediaMax(730px){
    grid-template-columns: 316px;
    margin-top: -50px;
    z-index: 5;
  }
}

.box {
  display: grid;
  background: url("../../assets/image/imgForOpenMysteryBoxPage/borderForMysteryBox.png") no-repeat;
  width: 310px;
  height: 314px;

  &:active {
    background: url("../../assets/image/imgForOpenMysteryBoxPage/activeBorderForMysteryBox.png") no-repeat;
  }
}

.titleBox {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

  margin-top: 34px;
}

.boxInCell {
  justify-self: center;
  margin-top: -20px;

  img {
    max-width: 220px;
    max-height: 200px;
  }
}

.characteristics{
  position: absolute;
  z-index: 10;
  display: grid;
  grid-template-columns: auto auto;

  width: 385px;
  height: 408px;

  background: url("../../assets/image/imgForOpenMysteryBoxPage/bgForCharacteristicsBuild.png") no-repeat;
  background-size: contain;

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: #FFFFFF;

  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

  padding: 0 32px 0;

  margin-left: 330px;
  @include mediaMax(1020px){
    display: none;
  }
}
.characteristicsLeft{
  position: absolute;
  z-index: 10;
  display: grid;
  grid-template-columns: auto auto;

  width: 385px;
  height: 408px;

  background: url("../../assets/image/imgForOpenMysteryBoxPage/bgForCharacteristicsBuild.png") no-repeat;
  background-size: contain;

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: #FFFFFF;

  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

  padding: 0 32px 0;

  margin-left: -410px;
  @include mediaMax(1020px){
    display: none;
  }
}
.characteristicsMiddle{
  display: none;
  @include mediaMax(1020px){
    position: absolute;
    z-index: 10;
    display: grid;
    grid-template-columns: auto auto;

    width: 385px;
    height: 408px;

    background: url("../../assets/image/imgForOpenMysteryBoxPage/bgForCharacteristicsBuild.png") no-repeat;
    background-size: contain;

    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;

    color: #FFFFFF;

    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

    padding: 0 32px 0;

    margin-left: 330px;
  }
  @include mediaMax(730px){
    display: none;
  }
}
.characteristicsLeftMiddle{
  display: none;
  @include mediaMax(1020px){
    position: absolute;
    z-index: 10;
    display: grid;
    grid-template-columns: auto auto;

    width: 385px;
    height: 408px;

    background: url("../../assets/image/imgForOpenMysteryBoxPage/bgForCharacteristicsBuild.png") no-repeat;
    background-size: contain;

    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;

    color: #FFFFFF;

    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

    padding: 0 32px 0;

    margin-left: -410px;
  }
  @include mediaMax(730px){
    display: none;
  }
}
.characteristicsLeftMobile{
  display: none;
  @include mediaMax(730px){
    position: absolute;
    z-index: 10;
    display: grid;
    grid-template-columns: auto auto;

    width: 308px;
    height: 327px;

    background: url("../../assets/image/imgForOpenMysteryBoxPage/bgForCharacteristicsBuild.png") no-repeat;
    background-size: contain;

    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 12px;

    color: #FFFFFF;

    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

    padding: 0 32px 0;


  }
}
.characteristicsTittle{
  margin-top: 8px;
  margin-bottom: 6px;

  grid-column: 1/3;
  align-self: center;
  justify-self: center;

  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;

  color: #BF48DF;

  text-shadow: 0 1px 18px rgba(191, 72, 223, 0.5);
}
.nameCharacteristics{
  justify-self: start;
  div{
    margin-bottom: 20px;
  }
}
.valueCharacteristics{
  justify-self: end;
  display: grid;

  justify-items: end;
  grid-auto-flow: row;
  align-content: start;

  div{
    text-align: right;
    margin-bottom: 20px;
  }
}


.button {
  margin-top: 60px;
  justify-self: end;
  @include mediaMax(1210px){
    position: relative;
    right: 110px;
  }
  @include mediaMax(1020px){
    right: 290px;
  }
  @include mediaMax(730px){
    right: 50%;
    translate: 50%;
  }
}

.addingInform {
  z-index: 11;

  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

  margin-top: 100px;
  margin-bottom: 90px;
  @include mediaMax(1210px){
    opacity: 1;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 80px;
  }
  @include mediaMax(730px){
    justify-items: start;
  }
}

.address {
  justify-self: start;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  img {
    margin-left: -24px;
  }
  @include mediaMax(1210px){
    justify-self: center;
  }
  @include mediaMax(730px){
    font-size: 18px;
    text-align: left;
    width: 316px;

    overflow-wrap: break-word;
    word-break: break-all;

    margin-left:0;
    img{
      margin-right: -14px;
    }
  }
}

.warning {
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  @include mediaMax(1210px){
    justify-self: center;
  }
  @include mediaMax(730px){
    font-size: 18px;
    text-align: left;

    width: 330px;

    overflow-wrap: break-word;
    word-break: break-all;

    margin-left: -30px;


    img{
      margin-right: -10px;
    }

  }

}
.sustainability{
  display: grid;
  justify-items: end;
  justify-self: end;
  margin-bottom: 20px;
}






