@import "/scss";

.content {
  min-height: 270px;
  margin-top: 150px;
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }


}

.title {
  font-size: $titleFontSize;
  width: 675px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  position: relative;
  line-height: 35px;
  // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 600px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

}

.Maintitle {
  font-weight: 400;
  font-size: 155px;
  line-height: 169px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 5px 3px 0px #e74ff1, 0px -3px 0px #b5e627;
}

.subTitleBlock {
  position: relative;
  margin-top: 100px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.subTitle {
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  color: #ffffff;
  position: absolute;
  z-index: 10;

  span {
    width: 758px;
    @include mediaMax(666px){
      font-size: 32px;
      line-height: 35px;
    }
  }
  @include mediaMax(1390px){
    justify-content: center;
    text-align: center;
    justify-items: center;
  }
  @include mediaMax(666px){
    margin-top: -50px;
  }
}

.left {
  margin-top: 200px;
  @include mediaMax(666px){
    margin-top: 50px;
  }

}

.letfTop {
  display: flex;
  gap: 120px;
  margin-bottom: 70px;
  @include mediaMax(1390px){
    justify-content: center;
    text-align: center;
    justify-items: center;
  }
  @include mediaMax(666px){
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 0;
    margin-bottom: 50px;
  }

}

.letfBottom {
  display: flex;
  gap: 120px;
  @include mediaMax(1390px){
    justify-content: center;
    text-align: center;
    justify-items: center;
    margin-bottom: 700px;
  }
  @include mediaMax(666px){
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 50px;
    margin-bottom: 340px;
    margin-top: -60px;
  }
}

.letfItemTitle {
  font-family: "Superstar";
  font-style: normal;
  font-weight: 400;
  font-size: 150px;
  line-height: 163px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 5px 3px 0px #e74ff1, 0px -3px 0px #b5e627;
  @include mediaMax(1390px){
    justify-content: center;
    text-align: center;
    justify-items: center;
  }
  @include mediaMax(666px){
    font-size: 100px;
    margin-bottom: -20px;
  }
}

.letfItemDescription {
  max-width: 194px;
  font-family: "Superstar";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: #ffffff;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
}
.forLandloards{
  @include mediaMax(1390px){
    margin-left: 60px;
  }
  @include mediaMax(666px){
    margin-left: 0;
    margin-top: 0;
  }
}

.letfItemText {
  width: 474px;
  font-family: "Superstar";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 39px;
  display: flex;
  align-items: center;

  color: #ffffff;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  @include mediaMax(666px){
    font-size: 20px;
    line-height: 22px;
    width: 320px;
  }
}

.img {
  position: absolute;
  right: -90px;
  top: 50px;
  @include mediaMax(1390px){
    top:740px;
    right: auto;
    left: 50%;
    translate: -50%;
  }
  @include mediaMax(666px){
    top:760px;
    width: 314px;
  }
}