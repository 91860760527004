@import "/scss";

.modal {
  height: 583px;
  width: 845px;
  display: grid;
  align-items: center;
  justify-items: center;
  background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgForOpenBox.png") no-repeat;
  @include mediaMax(865px){
    height: 633px;
    width: 536px;
    margin: 10px;
    background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgMediumModalWindow.png") no-repeat;
  }
  @include mediaMax(540px){
    background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgSmallForWait.png") no-repeat;
    height: 390px;
    width: 311px;
  }
  img {
    width: 342px;
    @include mediaMax(540px){
      width: 224px;
    }
  }
}
.imgNft{
  zoom: 120%;
  position: absolute;
  animation-duration: 4s;
  animation-name: animationImgNft;


  @include mediaMax(865px){
    zoom: 78%;
  }
  @include mediaMax(540px){
    zoom: 72%;
  }
}



@keyframes animationImgNft {
  from{
    opacity: 0;
    width: 0;
  }
  to{
    opacity:1;
    width: 48%;

  }

}
.imgNftAppeared{
  zoom: 200%;
}