@import "/scss";

.content{
  width: 100%;
  height: 680px;
  display: grid;
  justify-items: center;

  @include mediaMax(1117px){
    height: 1500px;
  }
}
.title{
  font-weight: 400;
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

  @include mediaMax(1117px){
    font-size: 42px;
    line-height: 48px;
  }
}

.sections{
  display: grid;
  grid-template-columns: 300px 300px 300px;
  column-gap: 46px;

  @include mediaMax(1117px){
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    row-gap: 0;
  }
}

.section1, .section2, .section3{
  width: 300px;
  height: 300px;

  background: url("../../assets/imageBox/imgForSectionSpecialPrizes/borderForSectionSpeciaolPrize.png") no-repeat;
  background-size: cover;

  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 150px auto;

  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;
}

.description{
  width: 260px;

}
.section3{
  padding-bottom: 16px;
  img{
    position: relative;
    top: 20px;
  }
}
.section2{
  background: url("../../assets/imageBox/imgForSectionSpecialPrizes/invertBorder.png") no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  img{
    position: relative;
    top: 28px;
  }
}
.section1{
  padding-bottom: 20px;
  img{
    position: relative;
    top: 28px;
  }
}
.bigGift{
  position: absolute;

  margin-right: 1290px;
  margin-top: 120px;
  @include mediaMax(1314px){
    display: none;
  }
}
.claperBoard{
  position: absolute;

  margin-left: 1250px;
  margin-top: 120px;
  @include mediaMax(1314px){
    display: none;
  }
}
.shadow{
  position: absolute;

  z-index: 10;
  margin-top: -300px;
  margin-right: -150px;

  overflow: hidden;
  white-space: nowrap;
  @include mediaMax(1240px){
    display: none;
  }
}