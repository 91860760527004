@import "/scss";

.modal {
  height: 583px;
  width: 845px;
  display: grid;
  align-items: center;
  justify-items: center;
  background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgForOpenBox.png") no-repeat;
  grid-template-rows: 20px auto;
  @include mediaMax(865px){
    height: 633px;
    width: 536px;
    margin: 10px;
    background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgMediumModalWindow.png") no-repeat;
  }
  @include mediaMax(540px){
    background: url("../../../../assets/image/imgForOpenMysteryBoxPage/bgSmallForWait.png") no-repeat;
    height: 390px;
    width: 311px;
  }
  div {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    text-align: center;

    color: #FFFFFF;
    margin-top: 220px;
    @include mediaMax(540px){
     font-size: 24px;
    }
  }

  img {
    margin-top: 80px;
    width: 287px;
    @include mediaMax(540px){
      width: 198px;
    }
  }
}