@import "/scss";

.content {
  min-height: 270px;
  margin-top: 170px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }
}

.title {
  font-size: $titleFontSize;
  width: 675px;
  height: 74px;
  color: #ffffff;
  text-align: start;
  position: relative;
  line-height: 35px;
  // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 600px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__text {
    position: absolute;
    z-index: 5;
  }

  .title__backBlock1 {
    width: 600px;
    height: 33px;
    background: #b5e627;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    bottom: 10px;
    left: 10px;
    @include mediaMax($screenLg) {
      width: 430px;
      // width: 225px;
      // height: 54px;
      // bottom: -10px;
    }
    @include mediaMax($screenMd) {
      width: 290px;
    }
  }

  .title__backBlock2 {
    width: 600px;
    height: 33px;
    background: #e74ff1;
    position: absolute;
    bottom: 25px;
    z-index: 3;
    // left: 10px;
    @include mediaMax($screenLg) {
      width: 430px;
      // width: 225px;
      // height: 54px;
      // bottom: 0px;
    }
    @include mediaMax($screenMd) {
      width: 290px;
    }
  }
}

.cards {
  // margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  // > * + * {
  //   margin-left: 50px;
  // }
}

.card {
  height: 350px;
  width: 355px;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  margin-top: 105px;
  margin: 105px 13px 0 13px;
  @include mediaMax($screenLg) {
    transform: scale(0.9);
    margin: 60px 10px 0 10px;
  }
  @include mediaMax($screenMd) {
    transform: scale(0.8);
    margin: 30px 5px 0 5px;
  }
}

.card__top {
  position: absolute;
  top: -55px;

  .card__topText {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
    font-family: "Superstar";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    color: #ffffff;
    margin-top: -3px;
  }
}

.card__text {
  width: 355px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  opacity: 0.7;
  height: 100%;
  padding: 0 27px;
  padding-top: 15px;
}

.coinsL,
.coinsR {
  position: absolute;
  @include mediaMax(1250px) {
    display: none;
  }
}

.coinsL {
  left: 0;
  top: 600px;
}

.coinsR {
  right: 0;
  top: 600px;
}
