@import "/scss";

.content {
  min-height: 710px;
  margin-top: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  position: relative;
}

.title {
  // width: 683px;
  font-size: $titleFontSize;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-bottom: 40px;

  svg {
    margin-left: -10px;
    position: absolute;
  }

  @include mediaMax($screenLg) {
    font-size: 45px;
    svg {
      height: 70px;
    }
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
    svg {
      height: 50px;
    }
  }
  @include mediaMax(1150px) {
    margin-bottom: 5px;
  }
}

.oneArrow {
  @include mediaMax($screenLg) {
    svg {
      width: 26px;
    }
  }
  @include mediaMax($screenMd) {
    svg {
      width: 21px;
    }
  }
  @include mediaMax(1150px) {
    margin-bottom: 70px;
  }
}

.SvgRoadmapLine {
  @include mediaMax($screenLg) {
    width: 4px;
  }
  @include mediaMax($screenMd) {
    width: 3px;
  }
  @include mediaMax(1150px) {
    margin-bottom: 60px;
  }
}

.subtitle {
  // font-family: $subMainFontFamily;
  width: 562px;
  font-size: $subtitleFontSize;
  width: 355px;
  height: 57px;
  background: #e74ff1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
}

.titleLeft {
  position: absolute;
  top: -84px;
  left: 0;
  @include mediaMax($screenLg) {
    top: -70px;
  }
  @include mediaMax($screenMd) {
    top: -55px;
  }
  @include mediaMax(500px) {
    width: 100%;
  }
  @include mediaMax(350px) {
    left: 50%;
    transform: translate(-50%, 0);
    width: 286px;
  }
}

.titleRigth {
  position: absolute;
  top: -84px;
  right: 0;
  @include mediaMax($screenLg) {
    top: -70px;
  }
  @include mediaMax($screenMd) {
    top: -55px;
  }
  @include mediaMax(500px) {
    width: 100%;
  }
  @include mediaMax(350px) {
    right: 50%;
    transform: translate(50%, 0);
    width: 286px;
  }
}

.stage {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 41px 0px;
  width: 100%;
  @include mediaMax($screenLg) {
    margin: 15px 0px;
  }
  @include mediaMax($screenMd) {
    margin: -10px 0px;
  }
}

.stage0__content {
  z-index: 2;
  padding: 70px 0;
  // position: absolute;
  width: 100%;
  min-width: 286px;
  font-family: $subMainFontFamily;
  display: flex;
  flex-wrap: wrap;
  background: radial-gradient(
                  100% 1117.47% at 100% 0%,
                  rgba(181, 230, 39, 0.15) 0%,
                  rgba(209, 145, 153, 0.04) 56.33%,
                  rgba(231, 79, 241, 0.15) 100%
  );
  @include mediaMax($screenLg) {
    padding: 40px 15px 130px 0;
  }
}

.stage0__content_row {
  > * + * {
    margin-top: 42px;
  }

  @include mediaMax(1250px) {
    > * + * {
      margin-top: 20px;
    }
  }
}

.stage1__content {
  padding: 70px 0;
  z-index: 2;
  min-width: 286px;
  width: 100%;
  font-family: $subMainFontFamily;
  display: flex;
  flex-wrap: wrap;
  min-width: 286px;
  background: radial-gradient(
                  100% 1117.47% at 100% 0%,
                  rgba(231, 79, 241, 0.15) 0%,
                  rgba(209, 145, 153, 0.04) 56.33%,
                  rgba(181, 230, 39, 0.1) 100%
  );
  // }

  @include mediaMax($screenMd) {
    min-height: 450px;
  }
  @include mediaMax($screenLg) {
    padding: 40px 0;
  }
  @include mediaMax($screenMd) {
    padding: 25px 0;
  }
}

.stage2__content {
  padding: 70px 0 50px 0;
  z-index: 2;
  width: 100%;
  min-width: 286px;
  font-family: $subMainFontFamily;
  display: flex;
  flex-wrap: wrap;
  background: radial-gradient(
                  100% 1117.47% at 100% 0%,
                  rgba(181, 230, 39, 0.15) 0%,
                  rgba(209, 145, 153, 0.04) 56.33%,
                  rgba(231, 79, 241, 0.15) 100%
  );
  @include mediaMax($screenLg) {
    padding: 40px 0;
  }
  @include mediaMax($screenMd) {
    padding: 25px 0;
  }
}

.stage3__content {
  padding: 70px 0;
  z-index: 2;
  width: 100%;
  min-width: 286px;
  background: radial-gradient(
                  100% 1117.47% at 100% 0%,
                  rgba(181, 230, 39, 0.15) 0%,
                  rgba(209, 145, 153, 0.04) 56.33%,
                  rgba(231, 79, 241, 0.15) 100%
  );
  font-family: $subMainFontFamily;
  display: flex;
  flex-wrap: wrap;
  @include mediaMax($screenLg) {
    padding: 40px 0 0 0;
  }
  @include mediaMax($screenMd) {
    padding: 25px 0 0 0;
  }
}

.stage4__content {
  padding: 70px 0;
  // position: absolute;
  z-index: 2;
  width: 100%;
  min-width: 286px;
  background: radial-gradient(
                  100% 1117.47% at 100% 0%,
                  rgba(181, 230, 39, 0.15) 0%,
                  rgba(209, 145, 153, 0.04) 56.33%,
                  rgba(231, 79, 241, 0.15) 100%
  );
  font-family: $subMainFontFamily;
  display: flex;
  flex-wrap: wrap;
  @include mediaMax($screenLg) {
    padding: 40px 0 100px 0;
  }
  @include mediaMax($screenMd) {
    padding: 25px 0 150px 0;
  }
}

.stage0__img {
  position: absolute;
  right: -4px;
  bottom: -15px;
  @include mediaMax(860px) {
    // zoom: 90%;
    right: -18px;
    bottom: -25px;
    transform: scale(0.9);
  }
  @include mediaMax(500px) {
    // zoom: 55%;
    right: -71px;
    bottom: -80px;
    transform: scale(0.55);
  }
}

.stage1__img {
  position: absolute;
  top: -610px;
  left: -580px;
  @include mediaMax($screenLg) {
    display: none;
  }
}

.stage2__flare1 {
  position: absolute;
  top: -610px;
  right: -580px;
  z-index: 0;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.stage2__flare2 {
  position: absolute;
  left: -600px;
  bottom: -650px;
  z-index: 0;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.stage1__flare {
  position: absolute;
  right: -600px;
  z-index: 0;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.stage3__img1 {
  position: absolute;
  top: -50px;
  left: -70px;
  @include mediaMax($screenMd) {
    // zoom: 70%;
    top: -60px;
    left: -80px;
    transform: scale(0.7);
  }
  @include mediaMax($screenSm) {
    // zoom: 60%;
    top: -130px;
    left: -70px;
    transform: scale(0.6);
  }
}

.stage3__img2 {
  bottom: -40px;
  right: -70px;
  position: absolute;
  @include mediaMax($screenMd) {
    right: -90px;
    bottom: -90px;
    transform: scale(0.7);
  }
}

.stage3__flare1 {
  position: absolute;
  left: -600px;
  top: -500px;
  z-index: 0;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.stage3__flare2 {
  position: absolute;
  right: -600px;
  top: -300px;
  z-index: 0;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.stage4__img {
  position: absolute;
  right: 25px;
  top: 60px;
  @include mediaMax(1250px) {
    // zoom: 110%;
    transform: scale(1.1);
    top: 150px;
    right: 15px;
  }
  @include mediaMax($screenLg) {
    top: 150px;
  }
  @include mediaMax($screenMd) {
    // zoom: 80%;
    transform: scale(0.8);
    top: 230px;
    right: -15px;
  }
}

.stage4__flare1 {
  position: absolute;
  right: -600px;
  top: -420px;
  z-index: 0;
  pointer-events: none;
  @include mediaMax($screenMd) {
    display: none;
  }
  @include mediaMax($screenMd) {
    display: none;
  }
}

.stage4__flare2 {
  position: absolute;
  left: -600px;
  top: -500px;
  z-index: 0;
  pointer-events: none;
  @include mediaMax($screenMd) {
    display: none;
  }
}

.flare {
  position: absolute;
  top: -600px;
  pointer-events: none;
  z-index: 0;
  @include mediaMax($screenMd) {
    top: -140px;
    svg {
      width: 502px;
      height: 308px;
    }
  }
}
