@import "/scss";

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper {
  //   padding-left: 100px;
  height: 550px;

  @media screen and (max-width: 992px) {
    height: 500px;
    margin-top: -20px;
  }
  @media screen and (max-width: 768px) {
    height: 400px;
  }
  @media screen and (max-width: 576px) {
    height: 320px;
  }
}

.ecosystemSlide {
  transition: 0.5s !important;
  transform: scale(0.6) !important;
  margin: 0 -5px;
  font-size: 23px;
  @media screen and (max-width: 992px) {
    margin: 0px;
  }
  @media (min-width: 992px) and (max-width: 1330px) {
    margin: 0 3px;
  }
}

// 992px
.ecosystemSlidePrev {
  transform: scale(0.8) !important;
  font-size: 23px;
  margin-left: -20px;
  @media (min-width: 992px) and (max-width: 1330px) {
    transform: scale(0.9) !important;
  }
}

.ecosystemSlideNext {
  transform: scale(0.8) !important;
  font-size: 23px;
  margin-right: -20px;
  @media (min-width: 992px) and (max-width: 1330px) {
    transform: scale(0.9) !important;
  }
  // margin: 0 -4px;
}

.ecosystemSlideActive {
  margin: 0 40px;
  transform: scale(1) !important;
  font-size: 32px !important;
  @media screen and (max-width: 1100px) {
    margin: 0 20px;
    // display: none !important;
  }
  @media (min-width: 992px) and (max-width: 1330px) {
    margin: 0 10px;
  }
}

.swiper-pagination-bullet {
  width: 18px !important;
  height: 18px !important;
  background: #ffffff !important;
  border-radius: 0% !important;
  @media screen and (max-width: 768px) {
    width: 8.68px !important;
    height: 8.68px !important;
  }
  cursor: url("../../assets/image/Purpleheart_wand2.png"), auto !important;
}

.swiper-pagination-bullet-active {
  background: #b5e627 !important;
  box-shadow: 0px 0px 25px #b5e627;
}

// background: #B5E627;
// box-shadow: 0px 0px 25px #B5E627;
