@import "/scss";

.content {
  min-height: 270px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }
  @include mediaMax($screenMd) {
    min-height: 850px;
    margin-top: 80px;
  }
}

.title {
  font-size: $titleFontSize;
  width: 275px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 225px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__center {
    margin: 0 50px;
    @include mediaMax($screenLg) {
      margin: 0 0px;
    }
    @include mediaMax($screenMd) {
      margin: 0 -20px;
    }
  }

  .title__letf, .title__rigth {
    @include mediaMax($screenLg) {
      transform: scale(0.8);
    }
    @include mediaMax($screenMd) {
      transform: scale(0.5);
    }
  }

}

.text {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  max-width: 910px;
  margin-top: 35px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mediaMax($screenMd) {
    margin-top: 31px;
  }

  .text__center {
    margin: 0 30px;
    @include mediaMax($screenMd) {
      margin: 0;
    }
  }

  .text__right {
    @include mediaMax($screenMd) {
      display: none;
    }
  }

  .text__left {
    @include mediaMax($screenMd) {
      display: none;
    }
  }
}

.blocks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 90px;
  min-width: 950px;
  // overflow: auto;
  @include mediaMax(1200px) {
    display: none;
  }

}

.block {
  margin: 0 10px;
  width: 377px;
  height: 331px;
  background: radial-gradient(
                  123.08% 159.66% at 0% 0%,
                  #b5e627 0%,
                  #e74ff1 100%
  );
  border: 10px solid #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  @include mediaMax(1330px) {
    width: 350px;
  }
  @include mediaMax(1260px) {
    width: 320px;
    border: 8px solid #ffffff;
  }
}

.slider {
  margin-top: 90px;
  width: 100%;
  display: none;
  @include mediaMax(1200px) {
    display: block;
  }
  @include mediaMax($screenMd) {
    margin-top: 40px;
  }
}

.wrraper__block {
  @include mediaMax(1200px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
