@import "/scss";

.content {
  min-height: 270px;
  margin: 20px -40px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
    margin: 0;
  }
}

.title {
  font-size: $titleFontSize;
  width: 675px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  position: relative;
  line-height: 35px;
  // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 600px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__text {
    img {
      position: absolute;
      top: -90px;
      right: 120px;
    }
  }
}

.Maintitle {
  font-weight: 400;
  font-size: 155px;
  line-height: 169px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 5px 3px 0px #e74ff1, 0px -3px 0px #b5e627;
}

.subTitleBlock {
  width: 100%;
  position: relative;
  margin-top: 100px;
  height: 100px;

  display: flex;
  justify-content: flex-end;
}

.subTitle {
  max-width: 706px;
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: flex-end;
  text-align: right;

  color: #ffffff;
  position: absolute;
  z-index: 10;
  @include mediaMax(1250px) {
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    width: 700px;
  }
  @include mediaMax(720px) {
    margin-top: -30px;
    width: 320px;
    font-size: 32px;
    line-height: 35px;
  }
}

.subTitleStar {
  position: absolute;
  left: -85px;
  bottom: -34px;
}

.center {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 100px;
  position: relative;
  @include mediaMax(1250px) {
    justify-content: center;
  }
  @include mediaMax(720px) {
    margin-top: 10px;
  }
}

.items {
  display: flex;
  // flex-direction: column;
  gap: 39px;
  @include mediaMax(1320px) {
    gap: 26px;
  }
  @include mediaMax(1250px) {
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
  }


  .item {
    width: 377px;
    height: 406px;
    @include mediaMax(440px) {
      width: 290px;
    }
    border: 2px solid transparent;
    border-image: linear-gradient(to bottom, #e74ff1, #4fd4f1);
    -moz-border-image: -moz-linear-gradient(to bottom, #e74ff1, #4fd4f1);
    -webkit-border-image: -webkit-linear-gradient(to bottom, #e74ff1, #4fd4f1);
    border-image-slice: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 65px;
    color: #ffffff;
    font-size: 20px;
    line-height: 22px;
    @include mediaMax(1240px){
      width: 312px;
      height: 312px;

    }

    img {
      position: absolute;
      bottom: 10px;
      @include mediaMax(1240px){
        height: 197px;

      }
    }
  }

  .item2 {
    img {
      position: absolute;
      margin-top: -80px;
      bottom: 20px;
      @include mediaMax(1250px) {
        top: 510px;
      }
    }
  }

  .img1 {
    img {
      @include mediaMax(1250px) {
        top: 110px;
      }
    }
  }

}

.itemTitle {
  font-size: 64px;
  margin-top: 60px;
}

.imgBox {
  position: absolute;
  top: -400px;
  right: -530px;
}

.bottom {
  margin-top: 210px;
  font-weight: 400;
  font-size: 36px;
  line-height: 39px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  max-width: 1000px;
  z-index: 1;
}

.imgBottom {
  margin-top: -180px;
  margin-left: -20px;
}

.btn {
  position: absolute;
  bottom: 380px;
  right: -25px;
  @include mediaMax(940px) {
    bottom: 44px;
    right: auto;
    left: 50%;
    translate: -50%;
  }
}

.button{
  background: url("../../assets/image/raimbowButton.png");
}

.box {
  position: absolute;
  bottom: 170px;
  right: -10%;
  pointer-events: none;
  width: 320px;
  @include mediaMax(940px) {
    left: 50%;
    translate: -50%;
  }
}

.dots {
  margin-top: 80px;
  width: 100%;
  @include mediaMax(940px) {
    font-size: 18px;
    line-height: 20px;
  }
  @include mediaMax(940px) {
    margin-bottom: 400px;
  }

  .itemText {
    max-width: 700px;
  }

  .item {
    display: flex;
    gap: 22px;

    margin-bottom: 55px;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 30px;
    color: #ffffff;
    @include mediaMax(940px) {
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      justify-content: center;
    }

    .dot {
      margin-top: 10px;
      width: 18px;
      height: 18px;
      background: #b5e627;
      box-shadow: 0px 0px 25px #b5e627;
      @include mediaMax(940px) {
        display: none;
        position: absolute;
      }
    }
  }
}
.line1, .line2,.line3{
  display: none;
  @include mediaMax(931px){
    display: block;
    position: absolute;
    margin-top: -30px;
    left: 50%;
    right: auto;
    translate: -50%;
  }
}
