@import "/scss";

.content {
  min-height: 270px;
  margin-top: 150px;
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  font-family: "Superstar";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 39px;

  color: #ffffff;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  @include mediaMax($screenLg) {
    min-height: 400px;
  }

}

.subTitleBlock {
  position: relative;
  margin-top: 100px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.subTitle {
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  color: #ffffff;
  position: absolute;
  z-index: 10;
  @include mediaMax(880px){
    font-size: 32px;
    line-height: 35px;
    margin-top: -100px;
  }
  @include mediaMax(458px){
    margin-top: -160px;
  }

}

.subTitleLine {
  bottom: 30px;
  right: 430px;
  z-index: 5;
  position: absolute;
  width: 195px;
  height: 70px;
  background: linear-gradient(180deg, #4fd4f1 -13.57%, #e74ff1 125.71%);
  @include mediaMax(1343px){
      right: 316px;
  }
  @include mediaMax(1227px){
    right: 176px;
  }
  @include mediaMax(1088px){
    right: 0px;
  }
  @include mediaMax(880px){
    text-align: center;
    width: 103px;
    height: 36px;
    bottom: 164px;
    right: 345px;
  }
  @include mediaMax(810px){
    right: 256px;
  }
  @include mediaMax(689px){
    right: 214px;
  }
  @include mediaMax(640px){
    right: 156px;
  }
  @include mediaMax(586px){
    right: 86px;
  }
  @include mediaMax(516px){
    right: 0;
  }
  @include mediaMax(458px){
    bottom: 225px;
  }
  @include mediaMax(428px){
    bottom: 190px;
    right: 256px;
  }
  @include mediaMax(414px){
    right: 214px;
  }
  @include mediaMax(412px){
    bottom: 190px;
    right: 212px;
  }
  @include mediaMax(372px){
    right: 156px;
  }
  @include mediaMax(325px){
    right: 86px;
  }
  @include mediaMax(314px){
    right: 86px;
  }
}

.preText {
  margin-top: 130px;
  display: flex;
  align-items: center;
  gap: 17px;
  @include mediaMax(1153px){
    font-size: 32px;
  }
  @include mediaMax(1051px){
    font-size: 28px;
  }
  @include mediaMax(880px){
    text-align: center;
    margin-top: -60px;
  }
  @include mediaMax(458px){
    font-size: 18px;
    line-height: 119.48%;
    margin-top: -100px;
  }

  .preSquare {
    margin-top: -3px;
    width: 19px;
    height: 19px;
    background: #e74ff1;
    @include mediaMax(880px){
      text-align: center;
      position: relative;
      top: -20px;
    }
    @include mediaMax(458px){
      top: 0;
    }

  }
}

.center {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 94px;
  @include mediaMax(880px){
    justify-content: center;
    justify-items: center;
    position: relative;
    right: 50%;
    translate: 50%;
  }

  .text {
    max-width: 672px;
    text-align: right;
    @include mediaMax(1227px){
      right: 176px;
      width: 500px;
      height: 300px;
    }
    @include mediaMax(1047px){
      width: 400px;
    }
    @include mediaMax(880px){
      text-align: center;
      justify-content: center;
      justify-self: center;
      justify-items: center;
      margin-bottom: 300px;
      margin-top: -50px;

    }
    @include mediaMax(458px){
      font-size: 18px;
      line-height: 119.48%;
      height: 120px;
      width: 311px;
    }
    @include mediaMax(361px){
      margin-bottom: 170px;
    }
    

  }

  img {
    position: absolute;
    left: -120px;
    width: 500px;
    @include mediaMax(880px){
      margin-top: 340px;
      left: 50%;
      right: auto;
      translate: -50%;
    }
    @include mediaMax(458px){
      margin-top: 100px;
    }
    @include mediaMax(361px){
      width: 361px;
    }
  }
}

.stars {
  z-index: 1;
  margin-top: -100px;
  margin-right: -60px;
  @include mediaMax(880px){
    position: relative;
    top:70px;
    right: 50%;
    translate: 30%;
  }
}
.button{
  z-index: 10;
  background: url("../../assets/image/raimbowButton.png") no-repeat;
  @include mediaMax(1113px){
    padding-right: 20px;
    margin-right: 10px;
  }
  @include mediaMax(880px){
    position: relative;
    top:90px;
    right: 45%;
    translate: 45%;
    padding-left: 20px;
  }

}