@import "/scss";

.content {
  display: flex;
  margin-top: 30px;
  width: 815px;
  height: 514px;
  justify-content: flex-end;
  @include mediaMax(865px) {
    height: 633px;
    width: 536px;
    margin: 10px;
    justify-content: center;
  }
  @include mediaMax(540px) {
    height: 620px;
    width: 271px;
    padding: 0;
    margin: 0 -30px 0 0;
  }
}

.inputBlock {
  width: 100%;
}


.btn {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.titleMain {
  position: absolute;
  top: 12px;
  display: flex;
  justify-content: center;
  width: 75%;
  font-family: $mainFontFamily;
  font-size: 32px;
  color: white;
}

.right {
  display: grid;
  align-items: end;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120.48%;


  color: #FFFFFF;
}

.box {
  z-index: 5;
  position: absolute;
  left: 63px;
  top: 152px;
  width: 310px;
  @include mediaMax(865px) {
    width:200px;
    left: 50%;
    right: auto;
    translate: -50%;
    top: 20px;
    margin-left: 20px;
  }
  @include mediaMax(540px) {
    margin-left: 10px;
    width: 170px;
    top: 10px;

  }
}

.blockchain {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  justify-items: center;
  align-items: center;
  margin-right: 110px;
  margin-bottom: 0px;
  width: 300px;
  @include mediaMax(865px){
    display: none;
  }
}

.left {
  position: relative;
  left: -50px;

  z-index: 10;

  font-family: $mainFontFamily;
  width: 100%;
  max-width: 385px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120.48%;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);

  display: grid;

  @include mediaMax(865px) {
    margin-top: 180px;
    text-align: center;
    align-items: center;
    justify-items: center;
    left: 0;
  }
  @include mediaMax(540px) {
    margin-top: 120px;
    margin-left: -20px;
  }

  .preTitle {
    color: #e74ff1;
    font-size: 36px;
    line-height: 39px;

    @include mediaMax(540px) {
      font-size: 20px;
      margin-top: 30px;
    }
  }

  .title {
    font-size: 64px;
    line-height: 70px;
    display: flex;
    align-items: center;

    color: #ffffff;

    margin-bottom: 30px;
    margin-top: -30px;
    @include mediaMax(865px){
      margin-top: -10px;
    }

    @include mediaMax(540px) {
      font-size: 32px;
      margin-bottom: 30px;
      justify-self: center;
    }
  }

  .text {
    width: 430px;
    margin-bottom: 40px;

    font-weight: 400;
    font-size: 20px;
    line-height: 120.48%;
    @include mediaMax(540px) {
      font-size: 16px;
      width: 260px;
      height: 150px;
      margin-bottom: 16px;
      margin-top: -30px;
    }
  }

  .wInputRange {
    border: 2px solid #433E5E;
    border-radius: 6px;

    padding: 4px 6px;

  }


  .button {
    background: url("../../../../assets/image/raimbowButton.png") no-repeat;
    background-size: contain;

    width: 150px;
  }

  .buyBlock {
    width: 430px;

    font-weight: 400;
    font-size: 42px;
    line-height: 120.48%;

    display: grid;
    grid-template-columns: auto auto 150px;
    align-items: center;
    justify-items: center;

    margin-bottom: -16px;
    @include mediaMax(540px) {
      width: 100%;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto ;
      font-size: 32px;
      align-items: center;
      justify-items: center;
      .button{
        grid-column: 1/3;
        margin-bottom: 10px;
      }
    }
  }
}




.countBoxes {
  font-family: $subMainFontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 127%;
  text-align: center;

  color: #FFFFFF;

  width: 66px;
  height: 44px;

  background: #433E5E;
  border-radius: 4px;

  display: grid;
  align-items: center;
  justify-items: center;
}

.wCountBoxes {
  justify-content: center;
  align-self: center;
  justify-self: center;

  display: grid;
  grid-template-columns: 66px 30px;

  align-items: center;
  justify-items: center;
  gap: 13px;

  margin-bottom: 10px;
  margin-top: -10px;

  position: relative;
  left: 20px;
}

.totalText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120.48%;

  color: #FFFFFF;

  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: center;
  gap: 10px;

  margin-left: -6px;
}

.priceBoxTotal {
  width: 170px;

  display: grid;
  justify-items: center;
  align-items: center;
}

.discount {
  color: #B5E627;
}

.discountPrice{
  font-size: 20px;
  line-height: 120.48%;

  color: #655E76;

  margin-left:8px ;
  margin-right:8px ;

}

.line{
  position: absolute;

  width: 54px;
  height: 0;

  border: 1px solid #817B91;

  margin-top: -13px;
  margin-left: 66px;
}

.rangeSection{
  margin-top: -20px;
}
.informAboutPrice{
  margin-top: 10px;
  word-spacing: 2px;
}
.blueShadow{
  position: absolute;
  margin-top: -220px;
  margin-right: 110px;
}
.SliderCount{
  @include mediaMax(865px){
    width: 372px;
  }
  @include mediaMax(540px){
    width: 240px;
  }
}