@import "/scss";

.content {
  min-height: 270px;
  margin-top: 70px;
  margin-bottom: 120px;
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }
  // border-bottom: 2px solid #b5e627;
}

.title {
  font-size: $titleFontSize;
  width: 675px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  position: relative;
  line-height: 35px;
  // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 600px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__text {
    img {
      position: absolute;
      top: -90px;
      right: 120px;
    }
  }
}

.subTitle {
  width: 100%;
  font-size: 64px;
  line-height: 70px;
  max-width: 741px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  z-index: 10;
  @include mediaMax(1280px) {
    font-size: 32px;
    line-height: 35px;
    justify-content: center;
    text-align: center;
  }
}

.dots {
  margin-top: 80px;

  .item {
    display: flex;
    gap: 22px;
    margin-bottom: 70px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 30px;
    color: #ffffff;

    .dot {
      width: 18px;
      height: 18px;
      background: #b5e627;
      box-shadow: 0px 0px 25px #b5e627;
    }
  }
}

.blocks {
  display: flex;
  margin-top: 90px;
  gap: 22px;
  width: 600px;
  @include mediaMax(12830px) {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 22px;
    align-items: center;
    justify-items: center;
  }
  @include mediaMax(920px) {
    width: auto;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto;
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 268px;
    height: 327px;
    border: double 2px transparent;
    // border-radius: 80px;

    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  .block1 {
    background-image: linear-gradient(#0b0027, #0b0027),
    radial-gradient(
                    circle at top right,
                    rgba(231, 79, 241, 1),
                    rgba(79, 212, 241, 0),
                    rgba(79, 212, 241, 0),
                    rgba(181, 230, 39, 1)
    );
  }

  .block2 {
    background-image: linear-gradient(#0b0027, #0b0027),
    radial-gradient(
                    circle at top left,
                    rgba(231, 79, 241, 1),
                    rgba(79, 212, 241, 0),
                    rgba(79, 212, 241, 0),
                    rgba(79, 212, 241, 1)
    );
  }

  .block3 {
    background-image: linear-gradient(#0b0027, #0b0027),
    radial-gradient(
                    circle at top left,
                    rgba(181, 230, 39, 1),
                    rgba(79, 212, 241, 0),
                    rgba(79, 212, 241, 0),
                    rgba(79, 212, 241, 1)
    );
  }

  .blockTitle {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    color: #ffffff;
  }

  .blockText {
    padding: 0 15px;
    margin-top: 30px;
    font-family: $subMainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 151.68%;
    /* or 30px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    opacity: 0.9;
  }
}

.gradBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 27px;
  width: 848px;
  height: 174px;
  background: radial-gradient(
                  120.58% 2345.25% at -12.57% -35.92%,
                  #9bc71a 0%,
                  #12c1e8 54.63%,
                  #e63af1 100%
  );
  @include mediaMax(920px) {
    margin-top: 10px;
    height: 259px;
    width: 122%;
    margin-left: -11%;
    margin-right: -11%;
    padding-left: 4%;
    padding-right: 4%;

  }

  .gradBlockTitle {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    text-shadow: 3px 2px 0px #474747;
    @include mediaMax(520px) {
      font-size: 20px;
      line-height: 22px;
      text-shadow: 2px 1px 0px #474747;
    }
  }

  .gradBlockText {
    margin-top: 25px;
    max-width: 717px;
    font-family: $subMainFontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 151.68%;
    /* or 30px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    text-shadow: 1px 1px 0px #666666;
    @include mediaMax(520px) {
      font-size: 18px;
    }
  }
}

.img {
  position: absolute;
  right: -220px;
  bottom: 50px;
  top: 0;
  @include mediaMax(1230px) {
    visibility: hidden;
  }
}

.btn {
  margin-top: 50px;
  margin-left: 5px;
  @include mediaMax(773px){
    display: grid;
    justify-items: center;
    justify-content: center;
  }
}

.button {
  background: url("../../assets/image/raimbowButton.png") no-repeat;
}

.progressBar {
  margin-top: 60px;
  justify-content: center;
  justify-self: center;
  position: relative;
}

.wrapProgressBar {
  display: grid;
  justify-content: center;
}


