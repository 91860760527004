@import "/scss";

.content {
  margin-top: 50px;
  // min-height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  @include mediaMax($screenLg) {
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 300px;
  }
  @include mediaMax($screenMd) {
    margin-top: 0;
    margin-bottom: 270px;
  }
}

.title {
  width: 539px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: $titleFontSize;
  position: relative;

  svg {
    position: absolute;
  }

  @include mediaMax($screenLg) {
    font-size: 45px;
    svg {
      height: 70px;
      margin-top: -5px;
    }
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
    svg {
      height: 46px;
      margin-top: -3px;
    }
  }
}

.subtitle {
  font-family: $subMainFontFamily;
  max-width: 463px;
  font-size: $contetnFontSize;
  margin-top: 46px;
  @include mediaMax($screenLg) {
    text-align: center;
    font-size: 16px;
  }
  @include mediaMax($screenMd) {
    max-width: 311px;
  }
}

.paragraph {
  margin-bottom: 20px;
}

.right {
  min-height: 460px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include mediaMax($screenLg) {
    align-items: center;
  }
}

.left {
  position: absolute;
  left: 0;
  // left: 300px;
  top: -130px;
  pointer-events: none;
  @include mediaMax(1300px) {
    transform: translate(-35%, 0%);
  }
  @include mediaMax(1200px) {
    transform: translate(-51%, 0%);
  }
  @include mediaMax($screenLg) {
    top: 430px;
    left: 50%;

    transform: translate(-51%, 0%);
    img {
      width: 350px;
    }
  }
  @include mediaMax($screenMd) {
    top: 500px;
    // zoom: 50%;
    // transform: scale(0.5);
    transform: translate(-62%, 0%);
    img {
      width: 300px;
    }
  }

  .pixels {
    position: absolute;
    left: 0;
    bottom: 0;
    animation: infinite 3s imageAnimation;
  }

}

@keyframes imageAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.btns {
  margin-top: 75px;
  display: flex;

  > * + * {
    margin-left: 45px;
  }

  @include mediaMax($screenLg) {
    margin-top: 60px;
  }
}

.flare {
  position: absolute;
  right: -600px;
  top: -400px;
  pointer-events: none;
}
