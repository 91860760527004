@import "/scss";

.content {
  width: 100%;
  height: 1100px;
  position: relative;
  @include mediaMax(730px){
    height: 750px;
  }
}

.title {
  position: relative;
  top: -14px;
  opacity: 1;
  z-index: 2;

  display: grid;
  align-items: center;
  justify-items: center;
  width: 1232px;
  height: 268px;
  //background: url("../../assets/image/imgForOpenMysteryBoxPage/borderForOpenMysteryBox.png") no-repeat;
  @include mediaMax(1213px){
    background: url("../../assets/image/imgForOpenMysteryBoxPage/textMysteryBoxMedium.png") no-repeat center;
    img{
      position: absolute;
      display: none;
    }
  }
  @include mediaMax(730px){
    background: url("../../assets/image/imgForOpenMysteryBoxPage/textMysteryBoxSmall.png") no-repeat center;
  }

}

.bgShadow {
  position: absolute;
  left: 50%;
  translate: -50%;
  margin-top: -380px;
  z-index: 0;

  img {
    position: relative;
    z-index: 0;
  }
}

.areaForBox {
  position: relative;
  top: -70px;
  display: grid;
  grid-template-columns: 1fr 379px 1fr;
  align-items: center;
  justify-items: center;

  img {
    position: relative;
    z-index: 2;
  }
}

.mysteryBox {
  width: 379px;
  position: relative;
  top: 60px;
  @include mediaMax(1210px){
    width: 290px;
  }
  @include mediaMax(730px){
    width: 230px;
  }
}

.wImgBox {
  height: 750px;
}

.arrow {
  position: relative;
  left: 120px;
  top: -160px;
  @include mediaMax(1210px){
    left: -40px;
    top: -180px;
  }
  @include mediaMax(730px){
    left: -140px;
    top: -290px;
    width: 40%;
  }
}

.questionMark {
  position: relative;
  left: -170px;
  top: 20px;
  @include mediaMax(1210px){
    left: -300px;
    top: 20px;
  }
  @include mediaMax(730px){
    left: -240px;
    top: -180px;
    width: 24%;
  }
}

.lock {
  position: relative;
  right: -200px;
  top: -60px;
  @include mediaMax(1210px){
    right: -270px;
    top: -60px;
  }
  @include mediaMax(730px){
    right: -300px;
    top: -260px;
    width: 60%;
    transform: rotate(70deg);
  }
}

.inscription {
  position: relative;
  top: -120px;
  @include mediaMax(1210px){
    right: -230px;
    top: -180px;
  }
  @include mediaMax(730px){
    right: -340px;
    top: -250px;
    img{
      width: 200px;
    }
  }
}

.button {
  background: url("../../assets/image/imgForOpenMysteryBoxPage/buttonYourNfts.png") no-repeat;
  background-size: contain;
  width: 341px;
  height: 130px;
  display: grid;
  align-items: center;
  justify-items: center;
  margin-left: 50%;
  translate: -50%;

  position: relative;
  left: 20px;
  top: -70px;

  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 39px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);

  @include mediaMax(730px){
    width: 241px;
    padding-bottom: 40px;
    position: relative;
    top: -320px;
    font-size: 24px;
    left: 0;
}
}