@import "/scss";

.content {
  min-height: 270px;
  margin-top: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include mediaMax($screenLg) {
    min-height: 400px;
  }
}

.title {
  font-size: $titleFontSize;
  width: 275px;
  height: 74px;
  color: #ffffff;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  @include mediaMax($screenLg) {
    font-size: 45px;
    width: 225px;
  }
  @include mediaMax($screenMd) {
    font-size: 30px;
  }

  .title__backBlock1 {
    width: 275px;
    height: 64px;
    background: #b5e627;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    @include mediaMax($screenLg) {
      width: 225px;
      height: 54px;
    }
    @include mediaMax($screenMd) {
      font-size: 30px;
    }
  }

  .title__backBlock2 {
    width: 275px;
    height: 64px;
    background: #e74ff1;
    position: absolute;
    top: 10px;
    left: 10px;
    @include mediaMax($screenLg) {
      width: 225px;
      height: 54px;
    }
    @include mediaMax($screenMd) {
      font-size: 30px;
    }
  }
}

.text {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  max-width: 910px;
  margin-top: 35px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mediaMax($screenMd) {
    margin-top: 31px;
  }

  .text__center {
    margin: 0 30px;
    @include mediaMax($screenMd) {
      margin: 0;
    }
  }

  .text__right {
    @include mediaMax($screenMd) {
      display: none;
    }
  }

  .text__left {
    @include mediaMax($screenMd) {
      display: none;
    }
  }
}

.flare {
  position: absolute;
  top: -430px;
  pointer-events: none;
  z-index: 0;
  @include mediaMax($screenLg) {
    top: -160px;
    svg {
      width: 740px;
      height: 587px;
    }
  }
}
